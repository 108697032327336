import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col} from 'react-bootstrap';
import ReactPlayer from 'react-player'

const About = () => {



  return (
    <div >

      <header >
	  <h1>About Us</h1>
        <p>Buy Shares in Assets.
        </p>
<Container>
	  </Container>

      </header>
    </div>
  );
}

export default About;
